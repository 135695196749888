<template>
    <header class="gt-header header-layout1">
        <div class="sticky-wrapper">
            <!-- Main Menu Area -->
            <div class="menu-area">
                <div class="container">
                    <div class="row align-items-center justify-content-between">
                        <div class="col-auto">
                            <div class="header-logo">
                               
                                <router-link to="/">
                                    <img src="../../assets/Theme/CSS/img/logo-dark.png" alt="Beenify">
                                </router-link>
                            </div>
                        </div>
                        <div class="col-auto">
                            <nav class="main-menu d-none d-xl-inline-block">
                                <ul>
                                    <!-- <li class=""><a href="index.html">Home</a></li>
                                    <li><a href="">Industries</a></li>
                                    <li><a href="">Services</a></li>
                                    <li><a href="">Pricing</a></li> -->
                                    <!--li class="menu-item-has-children">
                                       <a href="service-1.html"> Services </a>
                                       <ul class="sub-menu">
                                          <li> <a href="service-1.html"> Services One</a> </li>
                                          <li> <a href="service-2.html"> Services Two</a> </li>
                                          <li> <a href="service-details.html"> Service Details </a> </li>
                                       </ul>
                                    </li-->
                                    <!-- <li><a href="">Contact</a></li> -->
                                </ul>
                            </nav>
                            <div class="header-button d-flex d-xl-none">
                                <button type="button" class="gt-menu-toggle sidebar-btn">
                                    <span class="line"></span>
                                    <span class="line"></span>
                                    <span class="line"></span>
                                </button>
                            </div>
                        </div>

                        <div class="col-auto d-none d-xl-block">

                            <div class="header-button">
                                <router-link to="/pricing" class="gt-btn">
                                    GET A FREE WEBSITE TODAY
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>
<script>
    /*  import eventBus from './event-bus';*/
    import AuthUserServices from '@/service/AuthUserServices.js';
    import userAuthService from '@/service/userAuthService';
    import { createConfirmDialog } from "vuejs-confirm-dialog";
    import UserService from '@/service/user.service';
    import Home from "../../views/HomeView.vue"
    import SimpleDialog from '@/views/shared/SimpleDialog.vue';
    import * as signalR from '@microsoft/signalr';
    import service from '@/service/FrontendService.js'
    export default {
        name: "HeaderView",
        components: {
            Home
        },
        //emits: ['logout'],
        data() {
             const hubConnection = new signalR.HubConnectionBuilder()
                 .configureLogging(signalR.LogLevel.Debug)
                 .withUrl('https://remoteservice.beenee.com/userHub',{
                /*.withUrl('https://localhost:7015/userHub', {*/
                    //skipNegotiation: true,
                    //transport: signalR.HttpTransportType.WebSockets
                })
                .build();
            let name = {
                firstName: '',
                lastName: ''
            }
            return {
                Shopping:'',
                scrollPosition: null,
                isscrolled: false,
                searchValue: '',
                homes: false,
                form: {
                    username: "",
                    password: "",
                },
                ShippingInformation: localStorage.getItem("ShippingInformation"),
                companyLogo: '',
                IsOpenLeftMenu: false,
                IsOpenRightMenu: false,
                hubConnection,
                companyname: localStorage.getItem('companyName'),

                companyId: localStorage.getItem("companyId"),
                showError: false, headerData: null, imageLink: null, name, logoPrimary: null, logoSecondary: null,
                isLoading: false, moduleList: [], activeIndex: 0, moduleList2: [],
                Svg: ' <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>',
                //userName: '' 
                userName: localStorage.getItem('userName'),
            }
        },
        props: ['NavLinks', 'isUserLoggedIn', 'sideMenuItems', 'name', 'ProfilePic', 'Headerpage'],


        created() {
            this.hubConnection.start({ transport: 'ServerSentEvents' }).then(() => {
                //alert('This connected for that time');
            });

         this.GetCartListData();;
            /* const globalVar = inject('globalVariable')*/
            this.getCompanyLogo();
            this.isMobile();

            //$('#cartOpen').click(function () {
            //    $('.cart-wrapper').addClass('open');
            //});
            //$('#cartClose').click(function () {
            //    $('.cart-wrapper').removeClass('open');

            //});

            //$(".mobile-nav-toggler").click(function () {
            //    $(".header-main-nav").addClass('open');
            //    $(".closeMask").addClass('active');
            //});
            //$(".closeMask").click(function () {
            //    $(this).removeClass('active');
            //    $(".header-main-nav").removeClass('open');

            //});
            $(document).ready(function () {

                $('#cartOpen').click(function () {
                    $('.cart-wrapper').addClass('open');
                });
                $('#cartClose').click(function () {
                    $('.cart-wrapper').removeClass('open');

                });

                $(".mobile-nav-toggler").click(function () {                   
                   
                    const element = document.querySelector('.closeMask')
                    const style = getComputedStyle(element)
                    const backgroundColor = style.display
                    
                    if(backgroundColor=='block')
                    {
                        $(".header-main-nav").removeClass('open');                    
                        $(".closeMask").removeClass('active');
                    }
                    else{
                         $(".header-main-nav").addClass('open');                    
                         $(".closeMask").addClass('active');
                    }
                    
                });                

                $(".closeMask").click(function () {
                    $(this).removeClass('active');
                    $(".header-main-nav").removeClass('open');
                });
                $(".mainmenu a").click(function () {
                    $(".closeMask").removeClass('active');
                    $(".header-main-nav").removeClass('open');
                });
            });

            //$(window).scroll(function () {
            //    if ($(window).scrollTop() >= 330) {
            //        $('.wrap-header').addClass('fixed');
            //    }
            //    else {
            //        $('.wrap-header').removeClass('fixed');
            //    }
            //});

            this.GetModuleList();
        },
        mounted() {
            this.hubConnection.on('ReceiveMessage', (message, user, count) => {
                this.GetCartListData();

            });
            //const storedUsername = JSON.parse(localStorage.getItem('username'));
            //console.log('this.storedUsername',localStorage.getItem('username'));
            //// Check if the username exists in localStorage
            ////console.log('user information',localStorage.getItem('userinfo'));
            //if (storedUsername) {
            //    // If exists, update the data property
            //    this.username = storedUsername.userName;
            //    alert(this.username);
            //}

            window.addEventListener('scroll', this.updateScroll);
            
        },
        methods: {
            async initializeSignalR() {
            debugger
                if (this.$hubConnection.state === signalR.HubConnectionState.Disconnected) {
                    try {
                        await this.$hubConnection.start();
                    } catch (error) {
                        console.error('Error starting SignalR connection:', error);
                    }
                    this.$hubConnection.onreconnected(connectionId => {
                    });
                }
            },
            goToPreviousPage() {
                this.$router.go(-1);
            },
            isMobile() {
                //debugger;
                if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    $(window).scroll(function () {
                        if ($(window).scrollTop() >= 60) {
                            $('.wrap-header').addClass('fixed_pos');
                        }
                        else {
                            $('.wrap-header').removeClass('fixed_pos');
                        }
                    });

                }
                else {
                    $(window).scroll(function () {
                        if ($(window).scrollTop() >= 330) {
                            $('.wrap-header').addClass('fixed');
                        }
                        else {
                            $('.wrap-header').removeClass('fixed');
                        }
                    });
                }
            },

            redirecttoCart(Id) {
                //debugger;
                if (localStorage.getItem('userId') == null || localStorage.getItem('userId') == '') {
                    this.$router.push({ path: `/Login` });
                }
                else {
                    this.$router.push({ path: `/Cart` })
                }
                
            },
            Homesss() {
                //debugger;
                this.homes = false;
            },
            Search() {
               // debugger;

                /*this.$router.push({ path: 'Home', params: { searchValue: this.searchValue } })*/

                this.searchValue;
                this.homes = true
            },
            goToPortalDashboard() {
                this.OpenRightMenu();
                /*        this.$emit('goToPortal')*/
                this.$router.push({ path: `/UserprofilePage` })
            },
            async Logout() {
                /*   const IPAddress = await UserService.GetIPAddress();*/
                const { reveal, onConfirm } = createConfirmDialog(SimpleDialog, {
                    question: `Are you sure you want to Logout?`,
                    header: `Logout`,
                    cancel: `No`,
                    confirm: `Yes`,
                });
                onConfirm(() => {
                    this.isLoading = true;

                    this.$router.push('/logoutRedirect/:origin');
                    setTimeout(() => { this.isLoading = false }, 2500);
                });
                reveal(() => {

                });
            },

            OpenLoginPage() {
                this.$router.push({ path: 'Login' });
            },

            OpenSingupPage() {
                this.$router.push({ path: 'SignUp' });
            },

            getCompanyLogo() {
                let logoUrl = localStorage.getItem('companySecondaryLogo'); //localStorage.getItem('companySecondaryLogo');
                this.companyLogo = (this.$filters.IsNull(logoUrl, 'null') == 'null') ? require('../../assets/images/logo.png') : logoUrl;
            },
            //getCompanyLogo() {
            //    let logoUrl = "";

            //    AuthUserServices.GetDynamicContentForComapny(localStorage.getItem("companyId")).then(res => {
            //        console.log('logo detail for home', res.data)
            //        logoUrl = res.data.secondaryLogoLink;
            //        this.companyLogo = logoUrl;
            //    })
            //    //(this.$filters.IsNull(logoUrl, 'null') == 'null') ?
            //    if (logoUrl == null || logoUrl == "") {
            //        this.companyLogo = require('../../assets/images/logo/logo.png');
            //    }
            //},
            async GetCartListData() {
                this.isLoading = true;
               // debugger;

                await AuthUserServices.GetCartListData(localStorage.getItem('CurrentCartId'), "yes", localStorage.getItem('cartaction'), localStorage.getItem("userId"), null).then((response) => {
                  //  debugger;
                    this.Shopping = response.data._CartsModel.length;
                })
            },
            redirectOrderTracking() {
                if (!this.isUserLoggedIn) {
                    this.$router.push({ path: '/Login' })
                }
                else {
                    this.$router.push({ path: '/Orders' })
                }
            },
            OpenRightMenu() {
                this.IsOpenRightMenu = (this.IsOpenRightMenu) ? false : true;
                let navbar = document.getElementsByClassName("navbar-default");
                let pageBodyWrapper = document.getElementsByClassName("page-body-wrapper");
                let sectionFooter = document.getElementsByClassName("section-footer");
                let sectioncomparison = document.getElementsByClassName("comparisonblock");
                let footerbottom = document.getElementById('footer-bottom');
                if (this.IsOpenRightMenu) {
                    for (let i = 0; i < navbar.length; i++) {
                        navbar[i].classList.add("show-menu1");
                    }
                    for (let i = 0; i < pageBodyWrapper.length; i++) {
                        pageBodyWrapper[i].classList.add("show-menu1");
                    }
                    for (let i = 0; i < sectionFooter.length; i++) {
                        sectionFooter[i].classList.add("show-menu1");
                    }
                    for (let i = 0; i < sectioncomparison.length; i++) {
                        sectioncomparison[i].classList.add("show-menu1");
                    }
                    // footerbottom.classList.add("show-menu1");
                } else {
                    for (let i = 0; i < navbar.length; i++) {
                        navbar[i].classList.remove("show-menu1");
                    }
                    for (let i = 0; i < pageBodyWrapper.length; i++) {
                        pageBodyWrapper[i].classList.remove("show-menu1");
                    }
                    for (let i = 0; i < sectionFooter.length; i++) {
                        sectionFooter[i].classList.remove("show-menu1");
                    }
                    for (let i = 0; i < sectioncomparison.length; i++) {
                        sectioncomparison[i].classList.remove("show-menu1");
                    }
                    //  footerbottom.classList.remove("show-menu1");
                }

            }, ToggleLeftMenu(Action = null) {
                this.IsOpenLeftMenu = (this.IsOpenLeftMenu) ? false : true;
                let navbar = document.getElementsByClassName("navbar-default");
                let pageBodyWrapper = document.getElementsByClassName("page-body-wrapper");
                let sectionFooter = document.getElementsByClassName("section-footer");
                let footerbottom = document.getElementById('footer-bottom');
                if (this.IsOpenLeftMenu) {
                    for (let i = 0; i < navbar.length; i++) {
                        navbar[i].classList.add("show-menu");
                    }
                    for (let i = 0; i < pageBodyWrapper.length; i++) {
                        pageBodyWrapper[i].classList.add("show-menu");
                    }
                    for (let i = 0; i < sectionFooter.length; i++) {
                        sectionFooter[i].classList.add("show-menu");
                    }
                    //footerbottom.classList.add("show-menu");
                } else {
                    for (let i = 0; i < navbar.length; i++) {
                        navbar[i].classList.remove("show-menu");
                    }
                    for (let i = 0; i < pageBodyWrapper.length; i++) {
                        pageBodyWrapper[i].classList.remove("show-menu");
                    }
                    for (let i = 0; i < sectionFooter.length; i++) {
                        sectionFooter[i].classList.remove("show-menu");
                    }
                    //footerbottom.classList.remove("show-menu");
                }
            },
            CloseLeftMenu() {
                if (this.IsOpenLeftMenu) {
                    let navbar = document.getElementsByClassName("navbar-default");
                    let pageBodyWrapper = document.getElementsByClassName("page-body-wrapper");
                    let sectionFooter = document.getElementsByClassName("section-footer");

                    for (let i = 0; i < navbar.length; i++) {
                        navbar[i].classList.remove("show-menu");
                    }
                    for (let i = 0; i < pageBodyWrapper.length; i++) {
                        pageBodyWrapper[i].classList.remove("show-menu");
                    }
                    for (let i = 0; i < sectionFooter.length; i++) {
                        sectionFooter[i].classList.remove("show-menu");
                    }
                    this.IsOpenLeftMenu = false

                }
            },

            updateScroll() {
                this.scrollPosition = window.scrollY;
                if (this.scrollPosition != 0) {
                    this.isscrolled = true;
                }
                else {
                    this.isscrolled = false;
                }

            },
            checkCurrentRoute(item) {

                if (item.moduleCode == this.$router.currentRoute.value.meta.moduleCode) {
                    return true;
                }
                else {
                    return false;
                }
            },
            async GetModuleList() {
                var id = localStorage.getItem("userId");
                if (id != null) {
                    await userAuthService.GetModuleList(localStorage.getItem("userId"), "00Test").then(response => (
                        this.moduleList = [],
                        this.moduleList = response.data,
                        console.log("moduleList ", this.moduleList)
                    ))
                }
            },
            checkParentRoute(modulCode) {
                var parentModuleCode = this.moduleList.filter(itm => itm.moduleCode === modulCode);
                if (parentModuleCode[0].subModules != null) {
                    var childModuleCode = parentModuleCode[0].subModules.filter(itm => itm.moduleCode === this.$router.currentRoute.value.meta.moduleCode);
                    if (childModuleCode.length > 0) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
                else if (parentModuleCode[0].subModules == null) {
                    if (modulCode == this.$router.currentRoute.value.meta.moduleCode) {
                        return true;
                    }
                }
                return false;
            },

            ForSrceenChange() {

                let widthwindow = window.screen.width;

                let headerElement = document.getElementById('top-page-header')
                let sideElement = document.getElementById('nav-page-sidebar');

                if (widthwindow <= 991) {
                    sideElement.classList.add("close_icon");
                    headerElement.classList.add("close_icon");
                }
                else {
                    if (sideElement.classList != null) {

                        sideElement.classList.remove("close_icon");

                    }
                    if (headerElement.classList != null) {

                        headerElement.classList.remove("close_icon");

                    }

                }
            },
            redirectToPath(path) {
                if (path == 'home') {
                    window.location.assign(`${this.$custConfig.companyPortalUrl}/${localStorage.getItem('companyUrl')}/Home`)
                }
                this.ForSrceenChange();
                this.$router.push(path)
            },
            GetHeaderData() {

                UserService.getHeaderData(localStorage.getItem("userId"), '00Test').then(res => {
                    this.headerData = res.data;
                    this.imageLink = res.data.profilePic;

                })
            },
        },
        destroy() {
            window.removeEventListener('scroll', this.updateScroll)
        },


    }
</script>