<template>
    <Header  :isUserLoggedIn="isUserLoggedIn"    @logout="logout"  :NavLinks="NavLinks"></Header>
    <div class="page-body-wrapper">
        <div class="page-body">
            <router-view />
        </div>
    </div>
    <Footer id="footer-bottom"
            v-if="ShowHeaderFooter"
            :footerNavLinks1="footerNavLinks1"
            :footerNavLinks2="footerNavLinks2"
            :footerNavLinks3="footerNavLinks3"
            :FooterworkusNavLinks="FooterworkusNavLinks" :isUserLoggedIn="isUserLoggedIn" :currentYear="currentYear">
    </Footer>
    <!--<AssetComparePopupComponent v-if="isComparePopUpOpen" :AssetComapreArray="AssetComapreArray" @ShowAssetComparePopup="ShowAssetComparePopup" @CloseComparePopup="CloseComparePopup" @RemoveCompareImage="RemoveCompareImage"></AssetComparePopupComponent>
    <div class="modal-backdrop fade" id="isvisbleModalFade"></div>
    <subscribeNewsletter v-if="showsubscribenewsletter || isPopUpShown =='true'" @closenewslettermodel="closenewslettermodel" @permanentclose="permanentclose"></subscribeNewsletter>-->
    <Loading v-model:active="isLoading"></Loading>

    <!--<a href="#" :class="{'back-to-top d-flex align-items-center justify-content-center':true,'active':isscrolled}"><i class="houzez-icon icon-arrow-up-1 text-white"></i></a>-->
</template>

<script>
    import { createConfirmDialog } from "vuejs-confirm-dialog";
    import AuthUserServices from '@/service/AuthUserServices.js';
    import UserService from '@/service/FrontendService.js';
    import Header from '@/Layout/header/Header.vue';
    import Footer from '@/Layout/footer/Footer.vue';
    import SimpleDialog from "../views/shared/SimpleDialog.vue";


//    import FilterUtility1 from '@/assets/Theme/CSS/js/swiper-bundle.min.js';
//import FilterUtility2 from '@/assets/Theme/CSS/js/bootstrap.min.js';
//import FilterUtility3 from '@/assets/Theme/CSS/js/wow.min.js';
//import FilterUtility4 from '@/assets/Theme/CSS/js/jquery.waypoints.min.js';
//import FilterUtility5 from '@/assets/Theme/CSS/js/tilt.min.js';
//import FilterUtility6 from '@/assets/Theme/CSS/js/jquery.magnific-popup.min.js';
//import FilterUtility7 from '@/assets/Theme/CSS/js/jquery.counterup.min.js';
//import FilterUtility8 from '@/assets/Theme/CSS/js/gsap.min.js';
//import FilterUtility9 from '@/assets/Theme/CSS/js/main.js'

    export default {
        name: 'layoutName',
        beforeCreate: function () {
            this.themeName = localStorage.getItem('theme');
            if (this.themeName == 'beeneefy') {
                this.$options.components.Header = require('@/Layout/beeneefy/Header.vue').default;
                this.$options.components.Footer = require('@/Layout/beeneefy/Footer.vue').default;
                require('@/assets/Theme/CSS/css/bootstrap.min.css')
                require('@/assets/Theme/CSS/css/fontawesome.min.css')
                require('@/assets/Theme/CSS/css/animate.css')
                require('@/assets/Theme/CSS/css/magnific-popup.min.css')
                require('@/assets/Theme/CSS/css/swiper-bundle.min.css')
                require('@/assets/Theme/CSS/css/main.css')
                 /*require('@/assets/Theme/CSS/js/swiper-bundle.min.js')*/

               
            }
            

        },
        components: {

            Header: () => {
                if (this.themeName == 'beeneefy') {
                    import('@/Layout/beeneefy/Header.vue');
                
                }


            },
            Footer: () => {
                if (this.themeName == 'beeneefy') {
                    import('@/Layout/beeneefy/Footer.vue');
                }

            },
            
        },
        components: {
            Header,
            Footer
        },
        data() {
            let themeName = localStorage.getItem('theme');
            let NavLinks; let footerNavLinks1; let footerNavLinks2; let footerNavLinks3; let HeaderTopNavlinks;let FooterAboutusNavLinks;let FooterworkusNavLinks;
            if (this.$router.options.HeaderNavlinks != null && this.$router.options.HeaderNavlinks != "") {
                NavLinks = JSON.parse(this.$router.options.HeaderNavlinks[0]);
            }
            else {
                NavLinks = JSON.parse(localStorage.getItem("HeaderNavlinks"));
            }
            if (this.$router.options.HeaderNavlinks != null && this.$router.options.HeaderNavlinks != "") {
                footerNavLinks1 = JSON.parse(this.$router.options.FooterNavlinks1);
            }
            else {
                footerNavLinks1 = JSON.parse(localStorage.getItem("FooterNavlinks"));

            }
            if (this.$router.options.HeaderNavlinks != null && this.$router.options.HeaderNavlinks != "") {
                footerNavLinks2 = JSON.parse(this.$router.options.FooterNavlinks2);
            }
            else {
                footerNavLinks2 = JSON.parse(localStorage.getItem("FooterNavlinks"));

            }
            if (this.$router.options.HeaderNavlinks != null && this.$router.options.HeaderNavlinks != "") {
                footerNavLinks3 = JSON.parse(this.$router.options.FooterNavlinks3);
            }
            else {
                footerNavLinks3 = JSON.parse(localStorage.getItem("FooterNavlinks"));

            }

            if (this.$router.options.FooterworkusNavLinks != null && this.$router.options.FooterworkusNavLinks != "") {
                FooterworkusNavLinks = JSON.parse(this.$router.options.FooterworkusNavLinks[0]);
            }
            else {
                FooterworkusNavLinks = JSON.parse(localStorage.getItem("FooterworkusNavLinks"));
            }
            const FooterLogoDescription = 'Our property management software streamlines the entire occupancy process to save you time and money.';
            const CurrentYear = new Date().getFullYear();
            const Copyrights = `Copyright &copy;  ${CurrentYear} - ${localStorage.getItem('companyName')}. All Rights Reserved.`
            let isPopUpShown = localStorage.getItem('isSubscribePopupShown');
            let currentYear = new Date().getFullYear();
            return {
                currentYear,
                themeName,
                NavLinks,
                footerNavLinks1,
                footerNavLinks2,
                footerNavLinks3,
                FooterLogoDescription,
                //HeaderTopNavlinks,
                //FooterAboutusNavLinks,
                FooterworkusNavLinks,
                //OurAddress: '',
                //OurEmail: '',
                //OurPhone: '',
                //Copyrights,
                //isopenSignUpModal: false,
                //loginmodelopen: false,
                isUserLoggedIn: false,
                isLoading: true,
                //isCloseComparePopup: false,
                //AfterLoginRoute: 'Home',
                //isComparePopUpOpen: false,
                //comparePopupiewDetail: null,
                //AssetComapreArray: [],
                //isscrolled: false,
                //scrollPosition: 0,
                //showsubscribenewsletter: false,
                //timeinterval: 15000,
                ShowHeaderFooter: true,
                //isPopUpShown,
                name,
                ProfilePic: null,

            }
        },

        created() {
            this.isLoading = true;
            this.GetAddressDetailOfCompany();
            this.isUserLoggedIn = (localStorage.getItem('access_token')) ? true : false;
            if (this.isUserLoggedIn == true) {
                localStorage.setItem('userlogin', true);
            }
            else {
                localStorage.setItem('userlogin', false);
            }
            setTimeout(() => { this.isLoading = false }, 1000);
            //debugger;
            if (this.isPopUpShown == false) {
                localStorage.setItem('isSubscribePopupShown', 'true');

            }
            let commpanyUrl = localStorage.getItem('companyURL');
            let found = this.$route.fullPath.includes(commpanyUrl + '/dashboard');
            this.name = JSON.parse(localStorage.getItem('userinfo'));
            if (this.$filters.IsNull(this.name, true, null) != null) {
                this.ProfilePic = (this.$filters.IsNull(this.name.profilePic, true, null) == null) ? require('../assets/images/default.png') : this.name.profilePic;
            }
            this.ShowHeaderFooter = (found) ? false : true;
            this.GetDynamicContent();
        },
        mounted() {
            window.addEventListener('scroll', this.updateScroll);
            let companyID = localStorage.getItem('companyId')
            let companyName = localStorage.getItem('companyName')
            if (companyID == 'e42a359a-d517-483c-8d7d-5353ca886b41' || companyID == 'E42A359A-D517-483C-8D7D-5353CA886B41') {
                this.setTimerForSubsribePopUp();
            }

        },
        updated() {
            var CurrnetUserLoggedIn = (localStorage.getItem('access_token')) ? true : false;
            if (this.isUserLoggedIn != CurrnetUserLoggedIn) {
                this.isUserLoggedIn = CurrnetUserLoggedIn;
                this.name = JSON.parse(localStorage.getItem('userinfo'));
                this.ProfilePic = (this.$filters.IsNull(this.name.profilePic, true, null) == null) ? require('../assets/images/default.png') : this.name.profilePic;
            }
            if (this.isUserLoggedIn == true) {
                localStorage.setItem('userlogin', true);
            }
            else {
                localStorage.setItem('userlogin', false);
            }
            this.isPopUpShown = localStorage.getItem('isSubscribePopupShown');

        },
        methods: {
            goToPortal() {
                let UserName = JSON.parse(localStorage.getItem('userinfo')).userName
                let encryptedUserName = this.$helpers.EncryptUserName(UserName, this);
                window.location.assign(`${this.$custConfig.adminPortalUrl}loginCompanyUser/${encryptedUserName}/${location.host}/${localStorage.getItem('companyURL')}`)
            },
            setTimerForSubsribePopUp() {
                //let cookie = document.cookie;
                if (!this.isUserLoggedIn) {
                    setTimeout(() => {
                        this.showsubscribenewsletter = true;
                        this.$helpers.enbalePopUpCss();
                    }, this.timeinterval)

                }

            },
            closenewslettermodel() {
                this.showsubscribenewsletter = false;
                this.timeinterval = 300000
                this.setTimerForSubsribePopUp();
                this.$helpers.disablePopUpCss();
                localStorage.setItem('isSubscribePopupShown', 'false');
            },
            permanentclose() {
                this.showsubscribenewsletter = false;
                this.$helpers.disablePopUpCss();
            },
            updateScroll() {
                this.scrollPosition = window.scrollY;
                // alert(this.scrollPosition)
                if (this.scrollPosition != 0) {
                    this.isscrolled = true;
                    setTimeout(() => {
                        if (this.scrollPosition != 0)
                            $("#headersticky").addClass("header-sticky")
                    }, 1000);

                }
                else {
                    this.isscrolled = false;
                    $("#headersticky").removeClass("header-sticky");
                }
            },
            async logout() {
             /*   const IPAddress = await UserService.GetIPAddress();*/
                const { reveal, onConfirm } = createConfirmDialog(SimpleDialog, {
                    question: `Are you sure you want to Logout?`,
                    header: `Logout`,
                    cancel: `No`,
                    confirm: `Yes`,
                });
                onConfirm(() => {
                    this.isLoading = true;
                    //UserService.LogoutHistory(localStorage.getItem('userId'), IPAddress).then(res => {
                    //})
                    localStorage.removeItem("userId");
                    localStorage.removeItem("access_token");
                    this.$router.push({ name: `HomePage` });
                    this.$router.go(0);
                    this.isUserLoggedIn = false;
                    setTimeout(() => { this.isLoading = false }, 2500);
                });
                reveal(() => {

                });
            },
            GetAddressDetailOfCompany() {
                try {
                    let companyAddress = JSON.parse(this.$filters.IsNull(localStorage.getItem('companyAddress'), true, null));
                    this.OurEmail = this.$filters.IsNull(companyAddress.email, true, ' - ');
                    this.OurPhone = this.$filters.IsNull(companyAddress.phone, true, ' - ');
                    this.OurAddress = this.$filters.IsNull(companyAddress.Address1, ' ') + ' ' + this.$filters.IsNull(companyAddress.Address2, ' ') + ', ' + this.$filters.IsNull(companyAddress.city, ' ') + ', ' + this.$filters.IsNull(companyAddress.state, ' ') + ', ' + this.$filters.IsNull(companyAddress.zip, ' ') + ', ' + this.$filters.IsNull(companyAddress.country, ' ');
                }
                catch (error) {
                    this.OurAddress = '';
                    this.OurEmail = '';
                    this.OurPhone = '';
                }
            },

            openLoginPage() {
                this.$router.push({ name: `Login` });
            },
            switchToAssetComapredetail(id) {
                this.$router.push({ path: `assetCompareDetails/${id}` });
            },
            CloseComparePopup() {
                this.AssetComapreArray = [];
                this.isComparePopUpOpen = false;
                this.$helpers.disablePopUpCss();
            },
            ShowAssetComparePopup(newassetValue) {
                let foundIndex = this.AssetComapreArray.findIndex(item => {
                    return item.id == newassetValue.id
                })

                //  Asset found
                if (foundIndex != -1) {
                    this.AssetComapreArray.splice(foundIndex, 1);
                }
                else {
                    if (this.AssetComapreArray.length < 4) {
                        this.AssetComapreArray.push(newassetValue);
                    }
                    else {
                        this.error("You can'not add more than 4 item");
                    }
                }
                this.$helpers.enbaleComparePopUpCss();
                this.isComparePopUpOpen = true;
            },
            CloseHeader() {
                try {
                    this.$refs.headerElement.CloseLeftMenu();
                }
                catch (error) {

                }
            },
            GetDynamicContent() {
                AuthUserServices.GetDynamicContentForComapny(localStorage.getItem("companyId")).then(res => {
                    this.FooterLogoDescription = res.data.footerContent
                })
            },
        },
    }
</script>



<!--<template>
        <Header />
    <div class="page-body-wrapper" >
        <div class="page-body">
            <router-view />
        </div>-->
<!-- END PAGE CONTENT -->
<!--</div>
    <Footer />
</template>
<script>

    import Header from '@/Layout/header/Header.vue';
    import Footer from '@/Layout/footer/Footer.vue';
    export default {
        name:"layoutName",
        components: {
            Header,
            Footer
            }
        }
</script>
<style scoped>

</style>-->
