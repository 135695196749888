

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import "bootstrap/dist/css/bootstrap.min.css"
/*import "bootstrap"*/
import "primevue/resources/themes/vela-green/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons 
import 'vue-loading-overlay/dist/css/index.css';
import axios from 'axios';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Paginator from 'primevue/paginator';
import ToastService from 'primevue/toastservice';
import PrimeVue from "primevue/config";
import Toast from "primevue/toast";
import Button from "primevue/button";
import FilterUtility from '@/Helpers/filters.js'
import HelperFunction from '@/Helpers/Helpers.js';
import { LoadingPlugin } from 'vue-loading-overlay';
import * as ConfirmDialog from "vuejs-confirm-dialog";
import bootstrap from '@/assets/css/bootstrap.min.css';
import '@/assets/css/style.css';
import '@/assets/css/imageurl.style.css';
import bootstrapJs from '@/assets/js/bootstrap.min.js';
import mainjqueryJs from '@/assets/js/jquery-3.7.1.min.js';
import Loading from '@/Layout/Loader.vue';
import VueLazyLoad from 'vue3-lazyload'


//import FilterUtility1 from '@/assets/Theme/CSS/js/swiper-bundle.min.js';

//import FilterUtility2 from '@/assets/Theme/CSS/js/bootstrap.min.js';

//import FilterUtility3 from '@/assets/Theme/CSS/js/wow.min.js';

//import FilterUtility4 from '@/assets/Theme/CSS/js/jquery.waypoints.min.js';

//import FilterUtility5 from '@/assets/Theme/CSS/js/tilt.min.js';

//import FilterUtility6 from '@/assets/Theme/CSS/js/jquery.magnific-popup.min.js';

//import FilterUtility7 from '@/assets/Theme/CSS/js/jquery.counterup.min.js';

//import FilterUtility8 from '@/assets/Theme/CSS/js/gsap.min.js';

//////import FilterUtility9 from '@/assets/js/main.js'

//import FilterUtility9 from '@/assets/Theme/CSS/js/main.js'

const app = createApp(App).use(router)

window.$ = window.jQuery = require('jquery');

app.use(PrimeVue)
app.use(LoadingPlugin);
app.use(ToastService);
app.use(ConfirmDialog);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('Paginator', Paginator);
app.component("Toast", Toast);
app.component("Button", Button);
app.component('Loading', Loading);
app.use(VueLazyLoad, {
    // options...
})
//golbal 
app.config.globalProperties.$filters = FilterUtility;
app.config.globalProperties.$Helpers = HelperFunction;
//app.config.globalProperties.$filters = HelperFunction;

//Services
app.provide('axios', axios);

app.mixin("", bootstrap);
app.mixin("", bootstrapJs);
app.mixin("", mainjqueryJs);

//app.mixin("", FilterUtility1);
//app.mixin("", FilterUtility2);
//app.mixin("", FilterUtility3);
//app.mixin("", FilterUtility4);
//app.mixin("", FilterUtility5);
//app.mixin("", FilterUtility6);
//app.mixin("", FilterUtility7);
//app.mixin("", FilterUtility8);
//app.mixin("", FilterUtility9);



app.mixin({
    methods: {
        success: function (msg) {
           // this.$toast.add({ severity: 'success', summary: "Success Message", detail: msg, life: 3000 });
            this.$toast.add({ severity: 'success', detail: msg, life: 2000, closable: false, });
        },
        error: function (msg) {
            //this.$toast.add({
            //    severity: 'error', summary: "Error Message", detail: msg, life: 3000
            //});
            this.$toast.add({
                severity: 'error', detail: msg, life: 2000, closable: false,
            });
        },
        warn: function (msg) {
            //this.$toast.add({
            //    severity: 'warn', summary: "Warning Message", detail: msg, life: 3000
            //});
            this.$toast.add({
                severity: 'warn', detail: msg, life: 2000, closable: false,
            });
        }
    },
})



app.use(function (req, res, next) {
    const isProduction = process.env.VUE_APP_PRODUCTION;
    if (isProduction.toLocaleLowerCase() == "true") {
        if (location.protocol === 'http:') {
            window.location.href = location.href.replace('http', 'https');
        }
    }
});
app.mount('#app');
