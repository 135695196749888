<template>
    <div v-if="isShowComponent!='beeneefy'">


        <!--<section class=" banner">
            <div class="container">
                <div class="bannerWrapper">
                    <div class="d-block d-lg-flex no-gutters align-items-center h-100">
                        <div class="col-12 col-xl-8 col-lg-8 order-lg-1">

                        </div>
                        <div id="home_banner_box" class="col-xl-5 col-lg-6 order-lg-0 h-100">
                            <div class="spc-banner">
                                <h2 class="h1 mb-20 text-white">All in One Farm Store</h2>
                                <p class="text-white">Your Animal Needs in One Place.</p>

                                <button class="btn btn-primary" @click="redirectproductlisting()">Shop Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>-->
        <section class="banner">
            <div class="container">
                <div>
                    <div id="home_banner_box" class="d-block d-lg-flex no-gutters align-items-center h-100 new-home-imageSlider">
                        <swiper :slides-per-view="1"
                                :space-between="0"
                                :modules="modules"
                                :navigation="true"
                                :loop="true"
                                autoplay="autoplay"
                                :pagination="false">
                            <swiper-slide class="first">

                                <div class="col-lg-12">
                                    <div class="offer_image">
                                        <div class="offter_text">
                                            <h2 class="h1 mb-20 ">Treat them <br /> to more dog toy</h2>
                                            <p class="">Their faves, priced right & delivered fast.</p>
                                            <!-- <button class="btn btn-primary" @click="redirectproductlisting()">Shop Now</button> -->
                                            <a class="btn btn-primary" href="/ProductList/Dog">Shop Now</a>
                                        </div>
                                        <div class="image_box"> <img src="../assets/images/slide3-2.jpg" alt="Service image"></div>

                                    </div>
                                </div>


                            </swiper-slide>



                            <swiper-slide class="second">
                                <div class="col-lg-12">
                                    <div class="offer_image">
                                        <div class="offter_text">
                                            <h2 class="h1 mb-20 text-white">Feline Medley</h2>
                                            <p class="text-white">
                                                Highly palatable and digestible diet for healthy cats of all ages.
                                                Provides complete and balanced nutrition.
                                            </p>
                                            <a class="btn btn-primary" href="/ProductList/Cat">Shop Now</a>
                                        </div>
                                        <div class="image_box"> <img src="../assets/images/slide1-1.jpg" alt="Service image"></div>
                                    </div>
                                </div>
                            </swiper-slide>


                            <swiper-slide class="third">
                                <div class="col-lg-12">
                                    <div class="offer_image">
                                        <div class="offter_text">
                                            <h2 class="h1 mb-20 text-white">All in One<br />Pet and Farm Store</h2>
                                            <p class="text-white">Your Animal Needs in One Place.</p>
                                            <a class="btn btn-primary" href="/ProductList/Horse">Shop Now</a>
                                        </div>
                                        <div class="image_box"> <img src="../assets/images/slide1-0.png" alt="Service image"></div>
                                    </div>
                                </div>
                            </swiper-slide>

                            <swiper-slide class="fourth">


                                <div class="col-lg-12">
                                    <div class="offer_image">
                                        <div class="offter_text">
                                            <h2 class="h1 mb-20 text-white">We care about your pet</h2>
                                            <p class="text-white">Your pet deserves more care!</p>
                                            <a class="btn btn-primary" href="/ProductList/Dog">Shop Now</a>
                                        </div>
                                        <div class="image_box"> <img src="../assets/images/slide2-2.jpg" alt="Service image"></div>
                                    </div>
                                </div>

                            </swiper-slide>

                            <swiper-slide class="fifth">

                                <div class="col-lg-12">
                                    <div class="offer_image">
                                        <div class="offter_text">
                                            <h2 class="h1 mb-20 text-white">Complete chick starter feed with</h2>
                                            <p class="text-white">all the unique nutrients they need</p>
                                            <a class="btn btn-primary" href="/ProductList/Hen">Shop Now</a>
                                        </div>
                                        <div class="image_box"> <img src="../assets/images/slide4-2-hen.jpg" alt="Service image"></div>
                                    </div>
                                </div>


                            </swiper-slide>

                            <swiper-slide class="sixth">

                                <div class="col-lg-12">
                                    <div class="offer_image">
                                        <div class="offter_text">
                                            <h2 class="h1 mb-20 text-white">Proudly American</h2>
                                            <p class="text-white">Supporting Small Business and Independent Farmers.</p>
                                            <a class="btn btn-primary" href="/ProductList">Shop Now</a>

                                        </div>
                                        <div class="image_box"> <img src="../assets/images/slide-banner6.jpg" alt="Service image"></div>
                                    </div>
                                </div>


                            </swiper-slide>

                            <swiper-slide class="third">
                                <div class="col-lg-12">
                                    <div class="offer_image">
                                        <div class="offter_text">
                                            <h2 class="h1 mb-20 text-white">Western Saddle<br />with Matching Tack Set</h2>
                                            <p class="text-white">Perfect cowboy saddle hand made and hand tooled.</p>
                                            <a v-if="IsTopDawg=='true'" class="btn btn-primary" href="/Products/bf1a0c0c-b759-405c-910e-370ede04bce0">Shop Now</a>
                                            <a v-else class="btn btn-primary" href="/Products/1b5f0c51-e4ba-4dce-a591-9a86643b06d7">Shop Now</a>
                                        </div>
                                        <div class="image_box"> <img src="../assets/images/Beenee-Banner-blank4-min.jpg" alt="Service image"></div>
                                    </div>
                                </div>
                            </swiper-slide>

                            <swiper-slide class="fifth">

                                <div class="col-lg-12">
                                    <div class="offer_image">
                                        <div class="offter_text" style="max-width: 640px;">
                                            <h2 class="h1 mb-20 text-white">Best Grooming Equipment for a Perfect Pet Pampering</h2>
                                            <a v-if="IsTopDawg=='true'" class="btn btn-primary mt-2" href="/Products/30275dbc-114e-492f-bee7-70a1fd795410">Shop Now</a>
                                            <a v-else class="btn btn-primary mt-2" href="/Products/030ee7b2-7c14-4799-b697-96fdeeb579ef">Shop Now</a>
                                        </div>
                                        <div class="image_box"> <img src="../assets/images/Beenee-Banner2-blank-min.jpg" alt="Service image"></div>
                                    </div>
                                </div>
                            </swiper-slide>

                            <swiper-slide class="fifth">

                                <div class="col-lg-12">
                                    <div class="offer_image">
                                        <div class="offter_text" style="max-width: 640px;">
                                            <h2 class="h1 mb-20 text-white">Boost Your Pet's Health</h2>
                                            <p class="text-white">Essential Vitamins and Supplements</p>
                                            <a v-if="IsTopDawg=='true'" class="btn btn-primary mt-3" href="/Products/e7bc2f90-5294-4854-a9ef-495ef706ad0c">Shop Now</a>
                                            <a v-else class="btn btn-primary mt-3" href="/Products/ea95da8e-fccb-4a07-8ec7-308b23a31a62">Shop Now</a>
                                        </div>
                                        <div class="image_box"> <img src="../assets/images/Beenee-Banner3-blank-min.jpg" alt="Service image"></div>
                                    </div>
                                </div>
                            </swiper-slide>

                            <swiper-slide class="third">
                                <div class="col-lg-12">
                                    <div class="offer_image">
                                        <div class="offter_text">
                                            <h2 class="h1 mb-20 text-white">Essential <span style="color: rgb(65 200 255) ">Feeders and Waterers</span> for Happy and Healthy Animals</h2>
                                            <a v-if="IsTopDawg=='true'" class="btn btn-primary mt-3" href="/Products/c8502ac4-cabe-457a-8cc0-89e9846ce47a">Shop Now</a>
                                            <a v-else class="btn btn-primary mt-3" href="/Products/840ec33e-4c18-461a-bfed-c60c99f13d98">Shop Now</a>
                                        </div>
                                        <div class="image_box"> <img src="../assets/images/Beenee-Banner-blank-min.jpg" alt="Service image"></div>
                                    </div>
                                </div>
                            </swiper-slide>

                        </swiper>
                    </div>
                </div>

            </div>
        </section>

        <section id="featured_category" class="container cstm-mrgin shopcatopt">
            <h2 class="heading text-center">Shop by Categories</h2>
            <div class="row pt-4 mx-n2" style="justify-content:center;">

                <div class="col-lg-12 mb-4">



                    <swiper :slides-per-view="2"
                            :space-between="10"
                            :modules="modules"
                            :navigation="true"
                            autoplay="autoplay"
                            :loop="false"
                            :pagination="false"
                            :breakpoints="{
                              '640': {
                                slidesPerView: 2,
                                spaceBetween: 20,
                              },
                              '768': {
                                slidesPerView: 4,
                                spaceBetween: 40,
                              },
                              '1024': {
                                slidesPerView: 5,
                                spaceBetween: 50,
                              },
                            '1920': {
                              slidesPerView: 7,
                              spaceBetween: 20,
                            },
                            }">
                        <swiper-slide v-for="(prodcatitem,index) in ProductCatgoryList">
                            <div class="categorybx">
                                <a @click="productdetails(prodcatitem.id)">
                                    <div class="catwrap"><img v-lazy="prodcatitem.imageUrl" class="img-fluid" /></div>
                                    <h3>{{prodcatitem.categoryName}}</h3>
                                </a>
                            </div>
                        </swiper-slide>
                    </swiper>

                </div>

            </div>
        </section>


        <!--  <section id="featured_category" class="container cstm-mrgin shopcatopt">
              <h2 class="heading text-center">Shop by Categories</h2>
              <div class="row pt-4 mx-n2" style="justify-content:center;">

                  <div class="col-lg-12 mb-4">

                      <swiper :slides-per-view="2"
                              :space-between="10"
                              :modules="modules"
                              :navigation="true"
                              autoplay="autoplay"
                              :pagination="false"
                              :breakpoints="{
                                    '640': {
                                      slidesPerView: 2,
                                      spaceBetween: 20,
                                    },
                                    '768': {
                                      slidesPerView: 4,
                                      spaceBetween: 40,
                                    },
                                    '1024': {
                                      slidesPerView: 5,
                                      spaceBetween: 50,
                                    },
                                  '1920': {
                                    slidesPerView: 7,
                                    spaceBetween: 20,
                                  },
                                  }">
                          <swiper-slide>
                              <div class="categorybx">
                                  <a href="#">
                                      <div class="catwrap"><img src="../assets/images/cat_pet.png" class="img-fluid" /></div>
                                      <h3>Pets</h3>
                                  </a>
                              </div>
                          </swiper-slide>
                          <swiper-slide>
                              <div class="categorybx">
                                  <a href="#">
                                      <div class="catwrap"><img src="../assets/images/cat_ranch.png" class="img-fluid" /></div>
                                      <h3>Ranch</h3>
                                  </a>
                              </div>
                          </swiper-slide>
                          <swiper-slide>
                              <div class="categorybx">
                                  <a href="#">
                                      <div class="catwrap"><img src="../assets/images/cat-horse.png" class="img-fluid" /></div>
                                      <h3>Horse</h3>
                                  </a>
                              </div>
                          </swiper-slide>
                          <swiper-slide>
                              <div class="categorybx">
                                  <a href="#">
                                      <div class="catwrap"><img src="../assets/images/cat-poultry.png" class="img-fluid" /></div>
                                      <h3>Poultry</h3>
                                  </a>
                              </div>
                          </swiper-slide>
                          <swiper-slide>
                              <div class="categorybx">
                                  <a href="#">
                                      <div class="catwrap"><img src="../assets/images/cat-hay.png" class="img-fluid" /></div>
                                      <h3>Feed/Hay</h3>
                                  </a>
                              </div>
                          </swiper-slide>
                          <swiper-slide>
                              <div class="categorybx">
                                  <a href="#">
                                      <div class="catwrap"><img src="../assets/images/cat-farm.png" class="img-fluid" /></div>
                                      <h3>Farm</h3>
                                  </a>
                              </div>
                          </swiper-slide>
                          <swiper-slide>
                              <div class="categorybx">
                                  <a href="#">
                                      <div class="catwrap"><img src="../assets/images/cat-lawn.png" class="img-fluid" /></div>
                                      <h3>Lawn & Garden</h3>
                                  </a>
                              </div>
                          </swiper-slide>
                          <swiper-slide>
                              <div class="categorybx">
                                  <a href="#">
                                      <div class="catwrap"><img src="../assets/images/cat-outdoor.png" class="img-fluid" /></div>
                                      <h3>Outdoor Power</h3>
                                  </a>
                              </div>
                          </swiper-slide>
                      </swiper>

                  </div>

              </div>
          </section>-->


        <section class="container ">
            <h2 class="heading text-center">Featured Products</h2>
            <div class="row pt-4 mx-n2">
                <!-- Product-->
                <div class="col-lg-12">
                    <div class="recommendProdct">
                        <div class="card product-card bg-grey" v-for="(prodcatitem,index) in RecommendedProductList">
                            <div class="discount-badge blank"></div>
                            <!--<div class="saving-badge">{{prodcatitem.discount}}%</div>-->
                            <!-- <a class="card-img-top d-block overflow-hidden positionRelative" @click="ProductDetailpage(prodcatitem.id)"> -->
                            <div class="card-img-top d-block overflow-hidden positionRelative">
                                <a class="card-img-top d-block overflow-hidden positionRelative" type="button" @click="ProductDetailpage(prodcatitem.id,prodcatitem.slugUrl)">
                                    <img :src="prodcatitem.imageUrl" height="250" :alt="prodcatitem.productName">
                                </a>
                                <div id="show_ondesktop" class="card-body card-body-hidden">
                                    <button href="#" class="btn btn-primary btn-sm d-block w-100 mb-2" data-bs-toggle="modal" v-if="prodcatitem.availableStock > 0" type="button" @click="Path(prodcatitem.id,null,null,prodcatitem.topDawgProductID)">
                                        Add to Cart
                                    </button>
                                    <button v-else class="btn btn-primary btn-sm d-block w-100 mb-2" disabled>
                                        <i class="ci-cart fs-sm me-1"></i>Out Of Stock
                                    </button>

                                    <div class="text-center">
                                        <a class="nav-link-style fs-ms" href="#quick-view" data-bs-toggle="modal" type="button" @click="ProductDetailpage(prodcatitem.id,prodcatitem.slugUrl)">
                                            <i class="fa fa-eye"></i>
                                        </a>
                                    </div>
                                </div>

                            </div>
                            <div class="card-body py-2">
                                <h3 class="product-title fs-sm"><a @click="ProductDetailpage(prodcatitem.id,prodcatitem.slugUrl)">{{prodcatitem.productName}}</a></h3>

                                <div class="product-price" v-if="prodcatitem.price.toFixed(2) == prodcatitem.salePrice.toFixed(2)">
                                    ${{prodcatitem.salePrice.toFixed(2)}}
                                </div>
                                <div class="product-price" v-else>
                                    <del>${{prodcatitem.price.toFixed(2)}}</del> ${{prodcatitem.salePrice.toFixed(2)}}
                                    <span class="discount-text-orange">({{prodcatitem.discount}}% Off)</span>
                                </div>

                            </div>
                            <div id="show_onMobile" class="card-body card-body-hidden">
                                <button class="btn btn-primary btn-sm d-block w-100 mb-2" v-if="prodcatitem.availableStock > 0" type="button" @click="Path(prodcatitem.id,null,null,prodcatitem.topDawgProductID)">
                                    <i class="ci-cart fs-sm me-1"></i>Add to Cart
                                </button>
                                <button v-else class="btn btn-primary btn-sm d-block w-100 mb-2" disabled>
                                    <i class="ci-cart fs-sm me-1"></i>Out Of Stock
                                </button>

                                <div class="text-center">
                                    <a class="nav-link-style fs-ms" href="#quick-view" data-bs-toggle="modal" type="button" @click="ProductDetailpage(prodcatitem.id,prodcatitem.slugUrl)">
                                        <i class="fa fa-eye"></i>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

        <section class="category-boxes-wrapper" v-if="IsTopDawg!='true'">
            <div class="container">
                <div class="row pt-4 mx-n2">
                    <div class="col-xl-3 col-lg-6 col-md-6">
                        <div class="categorybx_column">
                            <h3 class="catbx-head"><a href="/ProductList/Dog" style="color:#000;"><img src="../assets/images/dog-icon1.png" alt="icon" /> Dog</a></h3>
                            <div class="categorybx_flex column1">
                                <div class="categorybx_list">
                                    <div class="categorybx_list-img"><a href="/ProductDetail/7B616867-EBC4-41E0-974C-8FEFB0B0846D"><img src="../assets/images/prod_img.png" alt="prod"></a></div>
                                    <h3><a href="/ProductDetail/7B616867-EBC4-41E0-974C-8FEFB0B0846D">Dog Bowl, Durapet 75</a></h3>
                                </div>
                                <div class="categorybx_list">
                                    <div class="categorybx_list-img"><a href="/ProductDetail/031D6FBF-EF2D-4CB5-A580-8466D60D1CFB"><img src="../assets/images/prod_img2.png" alt="prod"></a></div>
                                    <h3><a href="/ProductDetail/031D6FBF-EF2D-4CB5-A580-8466D60D1CFB">Duvet Natural Shampoo</a></h3>
                                </div>
                                <div class="categorybx_list">
                                    <div class="categorybx_list-img"><a href="/ProductDetail/09383D5E-188C-4ECF-8954-5394B1DAC3B5"><img src="../assets/images/prod_img3.png" alt="prod"></a></div>
                                    <h3><a href="/ProductDetail/09383D5E-188C-4ECF-8954-5394B1DAC3B5">Dog Collar</a></h3>
                                </div>
                                <div class="categorybx_list">
                                    <div class="categorybx_list-img"><a href="/ProductDetail/821E354B-DFE7-44EF-93CB-A172A092E040"><img src="../assets/images/prod_img4.png" alt="prod"></a></div>
                                    <h3><a href="/ProductDetail/821E354B-DFE7-44EF-93CB-A172A092E040">Fornline Plus Flea Drops</a></h3>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-xl-3 col-lg-6 col-md-6">
                        <div class="categorybx_column">
                            <h3 class="catbx-head"><a href="/ProductList/Cat" style="color:#000;"><img src="../assets/images/cat-icon.png" alt="icon" /> Cat</a></h3>
                            <div class="categorybx_flex column2">
                                <div class="categorybx_list">
                                    <div class="categorybx_list-img"><a href="/ProductDetail/2B6C03D7-C9E0-403D-935C-E2A32FD12C17"><img src="../assets/images/cat-product1.png" alt="prod"></a></div>
                                    <h3><a href="/ProductDetail/2B6C03D7-C9E0-403D-935C-E2A32FD12C17">K.M.R. Liquid</a></h3>
                                </div>
                                <div class="categorybx_list">
                                    <div class="categorybx_list-img"><a href="/ProductDetail/BD15139C-54E6-4DA9-B3D9-52C4FE29FD58"><img src="../assets/images/cat-product2.png" alt="prod"></a></div>
                                    <h3><a href="/ProductDetail/BD15139C-54E6-4DA9-B3D9-52C4FE29FD58">Cat Balls</a></h3>
                                </div>
                                <div class="categorybx_list">
                                    <div class="categorybx_list-img"><a href="/ProductDetail/8635DCF6-A4EB-4FC4-B0B7-4615A34C941A"><img src="../assets/images/cat-product3.png" alt="prod"></a></div>
                                    <h3><a href="/ProductDetail/8635DCF6-A4EB-4FC4-B0B7-4615A34C941A">Wild Feline Canyon</a></h3>
                                </div>
                                <div class="categorybx_list">
                                    <div class="categorybx_list-img"><a href="/ProductDetail/0F2F17C2-9508-4F4E-9ED6-6B559077CF8A"><img src="../assets/images/cat-product4.png" alt="prod"></a></div>
                                    <h3><a href="/ProductDetail/0F2F17C2-9508-4F4E-9ED6-6B559077CF8A">Cat Pan Scoop</a></h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-3 col-lg-6 col-md-6">
                        <div class="categorybx_column">
                            <h3 class="catbx-head"><a href="/ProductList/Horse" style="color:#000;"><img src="../assets/images/horse-icon.png" alt="icon" />Horse</a></h3>
                            <div class="categorybx_flex column3">
                                <div class="categorybx_list">
                                    <div class="categorybx_list-img"><a href="/ProductDetail/CBC36824-3739-4703-ABB5-98D600CBB27E"><img src="../assets/images/cat-horse-product1.png" alt="prod"></a></div>
                                    <h3><a href="/ProductDetail/CBC36824-3739-4703-ABB5-98D600CBB27E">Daily Gold Stress Relief</a></h3>
                                </div>
                                <div class="categorybx_list">
                                    <div class="categorybx_list-img"><a href="/ProductDetail/4D333C77-C0B0-457B-BE05-873324F2A7E0"><img src="../assets/images/cat-horse-product2.png" alt="prod"></a></div>
                                    <h3><a href="/ProductDetail/4D333C77-C0B0-457B-BE05-873324F2A7E0">Equidex Apple Electrolyte</a></h3>
                                </div>
                                <div class="categorybx_list">
                                    <div class="categorybx_list-img"><a href="/ProductDetail/5E61B538-0D1F-439F-B364-9AA628BF13CF"><img src="../assets/images/cat-horse-product3.png" alt="prod"></a></div>
                                    <h3><a href="/ProductDetail/5E61B538-0D1F-439F-B364-9AA628BF13CF">Standlee Beet Pulp, Shred</a></h3>
                                </div>
                                <div class="categorybx_list">
                                    <div class="categorybx_list-img"><a href="/ProductDetail/AB894E73-2B9A-4936-A36F-B93A88E75DB5"><img src="../assets/images/cat-horse-product4.png" alt="prod"></a></div>
                                    <h3><a href="/ProductDetail/AB894E73-2B9A-4936-A36F-B93A88E75DB5">MannaPro Sho-Glo Horse</a></h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-3 col-lg-6 col-md-6">
                        <div class="categorybx_column ">
                            <h3 class="catbx-head"><a href="/ProductList" style="color:#000;"><img src="../assets/images/other-icon.png" alt="icon" />  Other Animals</a></h3>
                            <div class="categorybx_flex column4">
                                <div class="categorybx_list">
                                    <div class="categorybx_list-img"><a href="/ProductDetail/A577299E-90FB-40A0-A0E7-D2DAD8F10800"><img src="../assets/images/other-product1.png" alt="prod"></a></div>
                                    <h3><a href="/ProductDetail/A577299E-90FB-40A0-A0E7-D2DAD8F10800">Pelleted Bedding</a></h3>
                                </div>
                                <div class="categorybx_list">
                                    <div class="categorybx_list-img"><a href="/ProductDetail/C2217125-97B9-4D76-91C2-B70FEC6BF646"><img src="../assets/images/other-product2.png" alt="prod"></a></div>
                                    <h3><a href="/ProductDetail/C2217125-97B9-4D76-91C2-B70FEC6BF646">Rice Bran Pellet</a></h3>
                                </div>
                                <div class="categorybx_list">
                                    <div class="categorybx_list-img"><a href="/ProductDetail/188499B9-4E47-46EE-A025-4E2CC1A118B1"><img src="../assets/images/other-product3.png" alt="prod"></a></div>
                                    <h3><a href="/ProductDetail/188499B9-4E47-46EE-A025-4E2CC1A118B1">Layer Crumble</a></h3>
                                </div>
                                <div class="categorybx_list">
                                    <div class="categorybx_list-img"><a href="/ProductDetail/585DCF47-B43F-41CF-90B1-2A00E4B6D873"><img src="../assets/images/other-product4.png" alt="prod"></a></div>
                                    <h3><a href="/ProductDetail/585DCF47-B43F-41CF-90B1-2A00E4B6D873">Stall Dry 40 lb. </a></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!--<section class=" banner mt-4">
            <div class="container">
                <div class="bannerWrapper">
                    <div class="d-block d-lg-flex no-gutters align-items-center h-100">
                        <div class="col-12 col-xl-8 col-lg-8 order-lg-1">

                        </div>
                        <div id="home_banner_box" class="col-xl-5 col-lg-6 order-lg-0 h-100">
                            <div class="spc-banner">
                                <h2 class="h1 mb-20 text-white">All in One Farm Store</h2>
                                <p class="text-white">Your Animal Needs in One Place.</p>

                                <button class="btn btn-primary" @click="redirectproductlisting()">Shop Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>-->
        <!-- <section class="about-home mt-20 mb-5 ">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-7">
                        <h3>Story of Our Legendary Founder</h3>
                        <p>At Beenee, we are driven to offer premium-quality animal feed that meets  every customer's diverse nutritional and farming needs. We want every  meal to give life and happiness to your furry and feathered friends. Let  Beenee be the reason behind every wagging tail and happy cluck - a  promise of a wholesome and cheerful life for your beloved companions.</p>
                        <router-link class="btn btn-primary" to="/Aboutus">View More</router-link>
                    </div>
                </div>
            </div>
        </section>-->
        <!-- <section class="offerSect">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-12 col-md-12 col-lg-4 mb-3 mb-lg-0">
                        <div class="py-10 px-8 rounded offerCol div-offerSect-blogimage">
                            <div>
                                <h3 class="fw-bold mb-1">Apple and <br> Carrot</h3>
                                <p>
                                    The Best Animal Feed Online!
                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-12 col-lg-4 mb-3 mb-lg-0">
                        <div class="py-10 px-8 rounded offerCol div-offerSect-newsimage">
                            <div>
                                <h3 class="fw-bold mb-1">Hay</h3>
                                <p>The Best Animal Feed Online!</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-12 col-lg-4 mb-3 mb-lg-0">
                        <div class="py-10 px-8 rounded offerCol div-offerSect-Testimonialsimage">
                            <div>
                                <h3 class="fw-bold mb-1">Grass Feed</h3>
                                <p>The Best Animal Feed Online!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->
        <!--  <section class="container ">
            <h2 class="heading text-center">Top Selling Products</h2>
            <div id="top_selling_Products" class="row pt-4 mx-n2">

                <div class="col-lg-3 col-md-6 col-sm-6 px-2 mb-4" v-for="(productitem,index) in ProductList">
                    <div class="card product-card">
                        {{productitem.discount}}%

                        <a class="card-img-top d-block overflow-hidden" @click="ProductDetailpage(productitem.id)">
                            <img :src="productitem.imageUrl" height="350" alt="product">
                        </a>
                        <div class="card-body py-2">
                            <h3 class="product-title fs-sm">
                                <a @click="ProductDetailpage(productitem.id)">
                                    {{productitem.productName}}
                                </a>
                            </h3>
                            h3
                            <div class="product-price" v-if="productitem.price.toFixed(2) == productitem.salePrice.toFixed(2)">

                                ${{productitem.salePrice.toFixed(2)}}
                            </div>
                            <div class="product-price" v-else>
                                <del>${{productitem.price.toFixed(2)}}</del>
                                ${{productitem.salePrice.toFixed(2)}} <span class="discount-text-orange">({{productitem.discount}}% Off)</span>
                            </div>

                        </div>
                        <div class="card-body card-body-hidden d-flex">

                            <button v-if="productitem.availableStock > 0 " class="btn btn-primary btn-sm d-block w-100 mb-2" @click="Path(productitem.id,productitem.isShip,productitem.localDelCharges)">
                                <i class="ci-cart fs-sm me-1"></i>Add to Cart
                            </button>

                            <button v-else class="btn btn-primary btn-sm d-block w-100 mb-2" disabled>
                                <i class="ci-cart fs-sm me-1"></i>Out Of Stock
                            </button>


                            <div class="text-center">
                                <a class="nav-link-style fs-ms" href="#quick-view" data-bs-toggle="modal" type="button" @click="ProductDetailpage(productitem.id)">
                                    <i class="fa fa-eye"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>-->
        <!--   <section class="offerSect offersrow">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-12 col-lg-6 col-md-12 mb-3 mb-lg-0">
                        <div class="py-10 px-8 rounded offerCol div-Animal-image">
                            <div>
                                <div class="cat-badge">Animal Care </div>
                                <h3 class="font-40 text-white fw-bold mt-4 mb-3">Advanced Veterinary <br>Dentistry</h3>
                                <p class="text-white"> Practice Limited to Veterinary Dentistry </p>
                                <button class="btn btn-primary" @click="redirectproductlisting()">Shop Now</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-3 col-md-6 mb-3 mb-lg-0">
                        <div class="py-10 px-8 rounded offerCol smoffer mb-3 div-Pickup-image">
                            <div>
                                <h3 class="font-30  text-white fw-bold mb-1">Curbside Pickup</h3>
                                <p class="text-white"> Free Pick-up in Store </p>
                                <button class="btn btn-primary" @click="redirectproductlisting()">Shop Now</button>
                            </div>
                        </div>
                        <div class="py-10 px-8 rounded offerCol smoffer div-Delivery-image">
                            <div>
                                <h3 class="font-30  fw-bold text-white mb-1">Shipping Nationwide</h3>
                                <p class="text-white"> Same Day Fulfillment </p>
                                 <button class="btn btn-primary" @click="redirectproductlisting()">Shop Now</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-3 col-md-6 mb-3 mb-lg-0">
                        <div class="py-10 px-8 rounded offerCol div-sale-offer-image">
                            <div>
                                <p class="smHeading"> SALE </p>
                                <div class="perc"><b>20%</b> Off on</div>
                                <h3 class="lg-heading fw-bold mb-1">Equine</h3>
                                <p class="prod"> Products </p>

                                <button class="btn btn-primary" @click="redirectproductlisting()">Shop Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->
        <!--   <section class="offerSect ranchmap">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-12 col-md-12 mb-3 mb-lg-0">
                        <div class="py-10 px-8 rounded offerCol div-sale-ranchmap-image">
                            <div>
                                <h2 class="sub-heading text-white fw-bold mb-1">
                                    Fruit Picking & Farm Day
                                </h2>
                                <p class="text-white"> More Than 500+ Items </p>
                                <button class="btn btn-white" @click="redirectproductlisting()">Shop Now</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->
        <!--<section class="service-area">
            <div class="container">
                <div class="row row-cols-xl-4 row-cols-sm-2 row-cols-1 row--20">
                    <div class="col">
                        <div class="service-box service-style-2">
                            <div class="icon"><img alt="Thumbnail" src="../assets/images/service-icon1.png"></div>
                            <div class="content">
                                <h6 class="title">Free Pick-up in Store</h6>
                                <p>1 hour or less*</p>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="service-box service-style-2">
                            <div class="icon"><img alt="Thumbnail" src="../assets/images/service-icon2.png"></div>
                            <div class="content">
                                <h6 class="title">Same Day Delivery</h6>
                                <p>Buy it today. Get it today with same day delivery.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="service-box service-style-2">
                            <div class="icon"><img alt="Thumbnail" src="../assets/images/service-icon3.png"></div>
                            <div class="content">
                                <h6 class="title">Financing Available</h6>
                                <p>Calculate your payment today.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="service-box service-style-2">
                            <div class="icon"><img alt="Thumbnail" src="../assets/images/service-icon4.png"></div>
                            <div class="content">
                                <h6 class="title">Neighbor's Club </h6>
                                <p>Earn points with purchases.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>-->

        <Loading v-model:active="isLoading">
        </Loading>
    </div>

    <div v-if="isShowComponent=='beeneefy'">
        <HomeComponent></HomeComponent>
    </div>
</template>


<script>
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import { Form, Field } from 'vee-validate';
    import { Pagination, Navigation, Scrollbar, Autoplay } from 'swiper';
    import 'swiper/css/pagination';
    import 'swiper/css/navigation';
    import 'swiper/css/virtual';
    import service from '@/service/FrontendService.js'
    import * as Yup from 'yup';
    import InputMask from "@/views/shared/inputmask/inputmasktemplate.vue"
    import ECommerce from '@/service/ECommerce.js';

    import 'swiper/css';

    export default {
        name: 'HomeView',
        beforeCreate: function () {
            this.themeName = localStorage.getItem('theme');
            if (this.themeName == 'beeneefy') {
                this.$options.components.HomeComponent = require('@/views/beeneefy/HomeView.vue').default

            }


        },

        components: {
            HomeComponent: () => {
                if (this.themeName == 'beeneefy') {
                    import('@/views/beeneefy/HomeView.vue');
                }

            },

            Form, Field, InputMask,
            Swiper,
            SwiperSlide,
            /*Header*/
        },
        props: ['homes', 'searchValue'],
        data() {
            const companyId = localStorage.getItem("SupcompanyId")
            const schema = Yup.object().shape({
                firstname: Yup.string().required('First name is required'),
                lastname: Yup.string().required('Last name is required'),
                phone: Yup.string().required('Phone is required'),
                companyname: Yup.string().required('Company Name is required'),
                subject: Yup.string().required('Subject is required'),
                message: Yup.string().required('Message is required'),
                email: Yup.string().required('Email is required').email('Email is invalid').matches('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$', 'Email is invalid'),
            });
            const schemaemail = Yup.object().shape({
                email: Yup.string().required('Email is required').email('Email is invalid').matches('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$', 'Email is invalid'),
            });

            return {
                modules: [Pagination, Navigation, Scrollbar, Autoplay],
                schema,
                schemaemail, isShowComponent: false,
                firstname: '',
                lastname: '',
                contactusData: {
                    id: null,
                    name: '',
                    companyname: '',
                    email: null,
                    phone: '',
                    subject: '',
                    message: '',
                    type: '',
                    companyId: localStorage.getItem("SupcompanyId")
                },

                ProductCatgoryList: null,
                //TopProductCatgoryList: null,
                ProductList: '',
                RecommendedProductList: [],
                SaverTodayProductList: [],
                emailvalue: null,
                isLoading: true,
                isMarketPlace: true,
                IsFeateured: true,
                IsFeateureds: false,
                companyId,

                id: null,
                search: '',
                Headerpage: true,
                searchproduct: '00Test',
                CompanyDynamicContent: [],
                bannervideo: [],
                description: [],
                currentCompanyId: localStorage.getItem('currentcompanyId'),
                IsTopDawg: localStorage.getItem("IsTopDawg"), cartsList: [], BrowserUserId: null, IsSubscribe: false, CurrentCartId: '00Test'
            }
        },
        created() {
            this.isShowComponent = localStorage.getItem('theme');
            if (this.searchValue != null || this.searchValue != undefined) {
                this.searchproduct = this.searchValue;;
                this.$emit("Homesss");
            }

            this.isLoading = true;
            this.GetProductList();
            this.GetProductCatgoryList();
            this.GetSaverTodayProductList();
            this.GetRecommendedProductList();
            this.GetDynamicContent();
            (function () {
                const second = 1000,
                    minute = second * 60,
                    hour = minute * 60,
                    day = hour * 24;

                //I'm adding this section so I don't have to keep updating this pen every year :-)
                //remove this if you don't need it
                let today = new Date(),
                    dd = String(today.getDate()).padStart(2, "0"),
                    mm = String(today.getMonth() + 1).padStart(2, "0"),
                    yyyy = today.getFullYear(),
                    nextYear = yyyy + 1,
                    dayMonth = "09/30/",
                    birthday = dayMonth + yyyy;

                //today = mm + "/" + dd + "/" + yyyy;
                //if (today > birthday) {
                //    birthday = dayMonth + nextYear;
                //}
                //end

                const countDown = new Date(birthday).getTime(),
                    x = setInterval(function () {

                        const now = new Date().getTime(),
                            distance = countDown - now;
                    }, 0)
            }())

        },

        updated() {
            window.scrollTo(0, 0);
        },
        mounted() {
            //
            this.$route.params
            //window.addEventListener('storage', this.handleStorageChange);
        },
        beforeMount() {
            this.$filters.SetMetaTagsStaticPage("", "Beenee is your one-stop pet store, offering everything from nutritious food to cozy beds. Shop now and give your pets the best care!","Premium Pet Supplies for Dogs, Cats & Horses | Beenee")
        },
        beforeUnmount() {
            //window.removeEventListener('storage', this.handleStorageChange);
        },
        methods: {
            legendary() {
                this.$router.push({ path: `/Aboutus` })
            },
            DogBow(data) {
                //this.$router.push({ path: `/Aboutus` })

                this.$router.push({ path: '/ProductDetail/' + data });
            },
            GetDynamicContent() {
                //this.isLoading = true;
                ECommerce.GetDynamicContentForComapny(localStorage.getItem("companyId")).then(res => {
                    this.CompanyDynamicContent = res.data;
                    this.bannervideo = this.CompanyDynamicContent.bannerVideoLink.split(',');
                    if (this.CompanyDynamicContent.description != null) {
                        this.description.push(this.CompanyDynamicContent.description.split('</p>,'));
                        //this.isLoading = false;
                    }
                    else {
                        this.description = null;
                        //this.isLoading = false;
                    }
                    const favicon = document.getElementById("favicon");
                    favicon.href = this.CompanyDynamicContent.favIconLink;
                    this.$parent.$parent.FooterLogoDescription = res.data.footerContent;
                    //this.isLoading = false;
                })
            },
            headerfalse() {
                //
                this.hom = false;;
            },
            Search(value) {


                this.search = value
            },
            GetProductList() {
                this.isLoading = true;
                this.IsFeateured = false;
                this.companyId = '00Test'
                this.searchproduct = (this.searchproduct == null) ? '00Test' : this.searchproduct;
                ECommerce.GetProductListingFront_Home(this.isMarketPlace, this.IsFeateureds, this.companyId, this.searchproduct).then((response) => {

                    if (this.$filters.IsNull(response.data) != null) {
                        console.log('data', response.data);
                        this.ProductList = null;
                        this.ProductList = response.data
                    }
                    //this.isLoading = false;
                    setTimeout(() => { this.isLoading = false; }, 30000);

                }).catch((error) => {
                    this.isLoading = false;
                })
            },
            GetRecommendedProductList() {
                //this.isLoading = true;
                this.IsFeateured = false;
                this.companyId = '00Test';
                //let currentcompanyId = localStorage.getItem('currentcompanyId');
                //this.companyId = currentcompanyId;
                ECommerce.GetRecommededProductListingFrontHome(this.isMarketPlace, this.IsFeateured, this.companyId, this.searchValue).then((response) => {

                    if (this.$filters.IsNull(response.data) != null) {
                        ///console.log('data', response.data);
                        this.RecommendedProductList = response.data;
                    }
                    //this.isLoading = false;
                }).catch((error) => {
                    this.isLoading = false;
                })
            },
            GetSaverTodayProductList() {
                //this.isLoading = true;
                this.IsFeateured = false;
                this.companyId = '00Test'
                ECommerce.GetSaverTodayProductListingHome(this.isMarketPlace, this.IsFeateured, this.companyId).then((response) => {

                    if (this.$filters.IsNull(response.data) != null) {
                        //console.log('data', response.data);
                        this.SaverTodayProductList = response.data;
                    }
                    //this.isLoading = false;
                }).catch((error) => {
                    this.isLoading = false;
                })
            },
            GetProductCatgoryList() {
                debugger;
                //let currentcompanyId = localStorage.getItem('currentcompanyId');
                this.isLoading = true;
                this.companyId = '00Test';
                //this.companyId = currentcompanyId;
                this.IsFeateureds = true;
                ECommerce.GetProductCategoryListFrontHome(this.isMarketPlace, false, this.companyId).then((response) => {

                    if (this.$filters.IsNull(response.data) != null) {
                        console.log('ProductCatgoryListdata', response.data);
                        this.ProductCatgoryList = response.data;
                        //if (this.IsTopDawg==true || this.IsTopDawg=='true') {
                        //    this.ProductCatgoryList = this.ProductCatgoryList.slice(0, 10);
                        //}
                        //this.ProductCatgoryList = this.ProductCatgoryList.slice(0, 10);
                        setTimeout(() => { this.isLoading = false; }, 3000);
                    }
                    this.isLoading = false;
                }).catch((error) => {
                    this.isLoading = false;
                })
            },
            productdetails(Id) {
                this.$router.push({ path: `/Products/${Id}` })
            },

            redirectproductlisting() {
                //redirects to product page(all categories)
                this.$router.push({ path: `/ProductList` })
                // this.$router.push({ path: `/Products` })
            },
            onSubmit() {
                this.isLoading = true;
                this.contactusData.name = this.firstname + ' ' + this.lastname;
                this.contactusData.type = 'Request Demo';
                service.savecontactus(this.contactusData).then(res => {
                    this.isLoading = false;
                    if (res.data == 1) {
                        this.success('Your request has been submitted successfully. Our team will contact you soon.');
                        this.$router.push({
                            name: "thankyou",
                            state: {
                                routeMessage: {
                                    IsSuccess: true,
                                    displayMessage: 'Your request has been submitted successfully. Our team will contact you soon.'
                                }
                            }
                        });
                    }
                    else {
                        this.error('Something went wrong')
                    }
                }).catch(res => {
                    this.error("Something went wrong. Please contact site administrator.");
                    this.isLoading = false;
                })
            },
            ProductDetailpage(Id, slugUrl) {
                debugger;
                this.isLoading = true;
                if (slugUrl != null && slugUrl != "null") {
                    this.$router.push({ path: `/ProductDetails/${slugUrl}` });
                }
                else {
                    this.$router.push({ path: `/ProductDetail/${Id}` });
                }
            },
            Path(Id, isShip, localDelCharges, topDawgProductID) {
                this.isLoading = true;
                //if (localStorage.getItem('userlogin') == "false") {
                //    this.$router.push({ path: `/Login` })
                //}

                //else {
                let subscriptionInfo = localStorage.getItem("SubscriptionDetails");
                var subscriptiondata = JSON.parse(subscriptionInfo);
                if (subscriptiondata != null) {
                    if (subscriptiondata.subscriptiontype != null || subscriptiondata.subscriptiontype != 'undefined') {
                        this.error("You cannot add more than one product in subscription!");
                        this.isLoading = false;
                        return;
                    }
                }
                if (isShip == false) {
                    localStorage.setItem('IsShip', isShip)
                    localStorage.setItem('SelectedProductId', Id);
                    localStorage.setItem('localDelCharges', localDelCharges);
                }
                localStorage.setItem('cartaction', 'cartcalc');
                localStorage.removeItem('currentProductId');
                localStorage.setItem('currentProductId', Id);
                localStorage.removeItem('ProducttopDawgID');
                localStorage.setItem('ProducttopDawgID', topDawgProductID);
                //this.$router.push({ path: `/Cart/${Id}` })
                this.GetCartListData();
                //}
                this.isLoading = false;

            },
            //handleStorageChange(event) {
            //    debugger;
            //    if (event.key === 'currentcompanyId') {
            //        this.currentCompanyId = event.newValue;
            //    }
            //},

            GetCartListData() {
                debugger;
                var vm = this;
                vm.isLoading = true;
                let subscriptionInfo = localStorage.getItem("SubscriptionDetails");
                var subscriptiondata = JSON.parse(subscriptionInfo);
                if (subscriptiondata != null) {
                    if (subscriptiondata.subscriptiontype != null || subscriptiondata.subscriptiontype != 'undefined') {
                        vm.subscriptionPrice = subscriptiondata.subscriptionPrice;
                        vm.IsSubscribe = subscriptiondata.IsSubscribe;
                    }
                }
                let BrowserUserId = localStorage.getItem("CurrentCartBrowerUserId");
                if (BrowserUserId != null) {
                    this.BrowserUserId = BrowserUserId;
                }
                else {
                    this.BrowserUserId = null;
                }

                ECommerce.GetCartListData(localStorage.getItem('currentProductId'), "yes",
                    localStorage.getItem('cartaction'),
                    localStorage.getItem("userId"), vm.CurrentCartId, this.BrowserUserId, vm.IsSubscribe
                ).then((response) => {
                    vm.cartsList = response.data._CartsModel;
                    if (response.data._CartsModel != null && response.data._CartsModel.length > 0) {
                        localStorage.setItem("CurrentCartBrowerUserId", vm.cartsList[0].userID);
                        $('.jquert_text').text(response.data._CartsModel.length);
                        this.success('Your Product is added to cart.');
                    }
                    if (localStorage.getItem("CurrentCartId") == null || localStorage.getItem("CurrentCartId") == "undefined" || localStorage.getItem("CurrentCartId") == "" || localStorage.getItem("CurrentCartId") == "null") {
                        if (vm.cartsList.length > 0) {
                            localStorage.setItem("CurrentCartId", vm.cartsList[0].userID)
                        }

                    }
                    vm.isLoading = false;
                })
            },
        }
    }
</script>

<style scoped>
    .offter_text {
        position: absolute;
        top: 50%;
        left: 7%;
        text-align: center;
        padding: 20px;
    }
</style>

