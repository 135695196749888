<template>
    <footer class="footer">
        <section class=" bg-grey newlsetter">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-4 col-md-3">
                        <div class="newlsetterLogo">
                            <!--<img alt="Site Logo" src="../../assets/images/logo/logo.png" />-->
                            <router-link :to="{ name: 'Home'}">
                                <img :src="companySecondaryLogo" :alt="companyname">
                            </router-link>
                            <!--<p>Easy and trusted online shop in US.</p>-->
                            <p>{{companyfootercontent}}</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="newsletter_form">
                            <!--<form>
                                <input type="text" required="" class="form-control rounded-0" placeholder="Enter Email Address">
                                    <button type="submit" class="btn btn-dark rounded-0" name="submit" value="Submit">Subscribe</button>
                            </form>-->

                            <Form autocomplete="off" @submit="emailsubmit" ref="refData" :validation-schema="schemaemail" v-slot="{ errors }">
                                <Field type="text" name="email" v-model="emailvalue"
                                       :class="{ 'is-invalid': errors.email }"
                                       class="form-control rounded-0" maxlength="50" placeholder="Enter Email Address" />
                                <div class="invalid-feedback">{{errors.email}}</div>
                                <button type="submit" class="btn btn-dark rounded-0" name="submit" value="Submit">Subscribe</button>
                            </Form>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-3">
                        <div class="social" v-if="socialmedialinkList!=null && socialmedialinkList.length>0">
                            <h3>Follow us on</h3>
                            <div v-for="item in socialmedialinkList">
                                <a :href="[item.Url]" target="_blank"><i :class="[item.IconUsed.replace('fab', 'fa-brands')]"></i></a>
                            </div>
                            <!--<a href="#"><i class="fa-brands fa-instagram"></i></a>
                            <a href="#"><i class="fa-brands fa-facebook"></i></a>
                            <a href="#"><i class="fa-brands fa-youtube"></i></a>-->

                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="container">
            <div class="row g-4 py-4">

                <div class="col-12 col-md-12 col-lg-9">
                    <div class="row g-4">
                        <div class="col-6 col-sm-6 col-md-3">
                            <h6>Customer Solutions</h6>
                            <ul class="nav flex-column">
                                <!--<li class="nav-item"><a @click="redirectotrackorder()" class="nav-link">Track Your Order</a></li>
                                <li class="nav-item"><a @click="redirectoreturnpolicy()" class="nav-link">Return Policy</a></li>
                                <li class="nav-item"><a @click="redirectodeliveryoption()" class="nav-link">Delivery Options</a></li>
                                <li class="nav-item"><a @click="redirectotaxexemption()" class="nav-link">Tax Exemptions</a></li>
                                <li class="nav-item"><a @click="redirectocontact()" class="nav-link">Contact Us</a></li>
                                <li class="nav-item"><a @click="redirectoFaq()" class="nav-link">FAQs</a></li>-->
                                <li id="menu-item-17633" class="nav-item" v-for="link in footerNavLinks1">
                                <router-link class="nav-link" :to="{ name: link.routeUrl,params:link.params}">{{link.displayText}}</router-link>
                                </li>
                                </ul>
                                <h6 class="mt-3">Departments</h6>
                                <ul class="nav flex-column">
                                    <li id="menu-item-176335" class="nav-item" v-for="link in footerNavLinks3">
                                    <router-link class="nav-link" :to="{ name: link.routeUrl,params:link.params}" @click.native="scrollToTop">{{link.displayText}}</router-link>
                                    </li>
                                </ul>
                        </div>

                        <div class="col-6 col-sm-6 col-md-3">
                            <h6>Useful Links</h6>
                            <!-- list -->
                            <ul class="nav flex-column">
                                <li id="menu-item-176334" class="nav-item" v-for="link in footerNavLinks2">
                                    <router-link class="nav-link" :to="{ name: link.routeUrl,params:link.params}" @click.native="scrollToTop">{{link.displayText}}</router-link>
                                </li>
                                <li><router-link class="nav-link" href="javascript:;" to="/blog">Blogs</router-link></li>
                                <!--<li class="nav-item"><a href="#" class="nav-link">Who We Are?</a></li>
    <li class="nav-item"><a href="#" class="nav-link">Investor Relations</a></li>
    <li class="nav-item"><a href="#" class="nav-link">Community</a></li>
    <li class="nav-item"><a href="#" class="nav-link">Product Catalog</a></li>-->
                                <template v-if="isUserLoggedIn">
                                    <li>
                                        <router-link class="nav-link" to="/Orders">
                                            Order Tracking
                                        </router-link>
                                    </li>
                                </template>
                                <template v-else>
                                    <li>
                                        <router-link class="nav-link" to="/Login">
                                            Order Tracking
                                        </router-link>
                                    </li>
                                </template>
                                <!--<li>
                                    <router-link class="nav-link" to="/Aboutus">
                                        About Us
                                    </router-link>
                                </li>-->
                                <li>
                                    <router-link class="nav-link" to="/contactus">
                                        Contact Us
                                    </router-link>
                                </li>
                                <li>
                                    <a class="nav-link" href="https://beenee.com/sitemap.html">Sitemap</a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-6 col-sm-6 col-md-3">
                            <h6>Featured Categories</h6>
                            <!--<ul class="nav flex-column" v-if="IsTopDawg=='true'">
                                <li class="nav-item"><a class="nav-link" href="/ProductList">All Products</a></li>
                                <li class="nav-item"><a class="nav-link" href="/ProductList/DogFood">Dog Food</a></li>
                                <li class="nav-item"><a class="nav-link" href="/ProductList/CatBeds">Cat Beds</a></li>
                                <li class="nav-item"><a class="nav-link" href="/ProductList/HorseClothing">Horse Clothing</a></li>
                                <li class="nav-item"><a class="nav-link" href="/ProductList">Other Animals</a></li>
                                <li class="nav-item"><a class="nav-link" href="/Products/e72254ac-f002-4b99-9d0d-1046a17ff71d">Apparel</a></li>-->
                                <!--<li class="nav-item"><a class="nav-link" href="/Products/1c110220-558c-4125-892c-bdbfe108a399">Remedies</a></li>-->
                            <!--</ul>-->
                            <ul class="nav flex-column">
                                <li class="nav-item"><a class="nav-link" href="/ProductList">All Products</a></li>
                                <li class="nav-item"><a class="nav-link" href="/ProductList/DogFood">Dog Food</a></li>
                                <li class="nav-item"><a class="nav-link" href="/ProductList/Cat">Cat</a></li>
                                <li class="nav-item"><a class="nav-link" href="/ProductList/Horse">Horse</a></li>
                                <li class="nav-item"><a class="nav-link" href="/ProductList">Other Animals</a></li>
                                <li class="nav-item"><a class="nav-link" href="/Products/0478ba61-f31b-4026-9de2-547192aaee49">Apparel</a></li>
                                <li class="nav-item"><a class="nav-link" href="/Products/1c110220-558c-4125-892c-bdbfe108a399">Remedies</a></li>

                            </ul>

                            <!--<h6 class="mt-3">Credit Center</h6>
    <ul class="nav flex-column">-->
                            <!-- list -->
                            <!--<li class="nav-item"><a href="#" class="nav-link">TSC Credit Card Offers &<br> Financing Options</a></li>
    </ul>-->

                        </div>
                        <div class="col-6 col-sm-6 col-md-3">
                            <h6>Top Categories</h6>
                            <ul class="nav flex-column">
                                <!-- list -->

                                <li class="nav-item" v-for="(TopProductCatgory,index) in TopProductCatgoryList">
                                    <!--<router-link :to="`/Products/${TopProductCatgory.id}`"
                                                 class="nav-link">{{TopProductCatgory.categoryName}}
                                    </router-link>-->
                                    <div v-if="TopProductCatgory.slugUrl !='' && TopProductCatgory.slugUrl != null">
                                        <router-link :to="`/Products/${TopProductCatgory.slugUrl}`"
                                                     class="nav-link">
                                            {{TopProductCatgory.categoryName}}
                                        </router-link>
                                    </div>
                                    <div v-else>
                                        <router-link :to="`/Products/${TopProductCatgory.id}`"
                                                     class="nav-link">
                                            {{TopProductCatgory.categoryName}}
                                        </router-link>
                                    </div>
                                     </li>
                               
                            </ul>
                        </div>

                    </div>
                </div>
                <!--{{companyLogo}}-->
                <div class="col-12 col-md-12 col-lg-3">
                    <h6>Contact Information</h6>
                    <div class="row continfo">
                        <div class="col-12 flexcont">
                            <div class="info">
                                <!--<div class="phoneNum">1 001 234 5678</div>-->
                                <!--<p><a href="mailto:Support@beenee.com">Support@beenee.com</a></p>-->
                                <!-- <p v-if="companyphone!=null">Call Us 24/7 Free</p>
                                <div class="phoneNum" v-if="companyphone!=null">{{companyphone}}</div> -->                           
                                <!--<p class="phoneNum"><a href="tel:8187673060">(818) 767-3060</a></p>-->
                                <p class="phoneNum"><a :href="'tel:' + telPhone">{{companyphone}}</a></p>
                                <p>
                                    <a href="https://www.google.co.in/maps/place/11084+Sheldon+St,+Sun+Valley,+CA+91352,+USA/@34.2543585,-118.3751048,17z/data=!3m1!4b1!4m6!3m5!1s0x80c293ec5f92eea3:0xbc4e0427a3a70a45!8m2!3d34.2543541!4d-118.3725299!16s%2Fg%2F11b8v62gtr?entry=ttu&g_ep=EgoyMDI0MDkyMi4wIKXMDSoASAFQAw%3D%3D" target="_blank">11084 , Sheldon St, Sun Valley, California (CA), 91352 , United States</a>
                                </p> 
                                <p><a href="mailto:Support@beenee.com">{{companyemail}}</a></p>
                            </div>
                            <div class="app-link">
                                <a href="https://play.google.com/store/apps/details?id=com.beenee.app" target="_blank"><img src="../../assets/images/google-play.png" /></a>
                                <a href="https://apps.apple.com/us/app/beenee/id6504921788" target="_blank"><img src="../../assets/images/app-store.png" /></a>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

            <div class="border-top py-4 copyrightsect">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <span class="small text-muted">
                            &#169; {{currentYear}} Beenee. All rights reserved.
                        </span>
                    </div>
                    <div class="col-md-6" style="text-align:right">
                        <img src="../../assets/images/payment.png" />
                    </div>
                </div>
            </div>
        </div>
    </footer>


    <!--<footer class="footer-section footer">
        <div class="container">
            <div class="row pt-60 pb-60">
                <div class="col-lg-4 col-md-6">
                    <div class="pr-0 pr-lg-4">
                        <router-link :to="{ name: 'home'}">
                            <img class="mb-25" src="../../assets/images/logo.png" alt="">
                        </router-link>
                        <p>BeeNee asset management platform is designed to save your business time and money, by helping you get to market faster and sell/rent your assets quickly.</p>

                        <ul class="socialLinks" v-if="socialmedialinkList!=null && socialmedialinkList.length>0">
                            <li v-for="item in socialmedialinkList">
                                <a :href="[item.Url]" target="_blank"><i :class="[item.IconUsed.replace('fab', 'fa')]"></i></a>

                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-md col-sm-6">
                    <div class="pl-0 pl-md-3 mt-5 mt-md-0">
                        <h5 class="font-weight-600 mb-20">Solutions</h5>
                        <ul class="list-unstyled footer-links">
                            <li><router-link to="/pricing">Pricing</router-link></li>
                            <li><router-link to="/TenantPortal">Tenant Portal</router-link></li>
                            <li><router-link to="/Leasing&Applications">Leasing & Applications</router-link></li>
                            <li><router-link to="/Marketing">Marketing</router-link></li>
                            <li><router-link to="/Communications">Communications</router-link></li>
                            <li><router-link to="/Accounting">Accounting</router-link></li>
                            <li><router-link href="javascript:;" to="/pressnews">Press Release</router-link></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-2 col-md col-sm-6">
                    <div class="pl-0 pl-md-3 mt-5 mt-md-0">
                        <h5 class="font-weight-600 mb-20">Company</h5>
                        <ul class="list-unstyled footer-links">
                            <li><router-link href="javascript:;" to="/Aboutus">About Us</router-link></li>
                            <li><router-link href="javascript:;" to="/contactus">Contact Us</router-link></li>
                            <li><router-link href="javascript:;" to="/blog">Blogs</router-link></li>
                            <li><router-link href="javascript:;" to="/FAQ">FAQ's</router-link></li>
                            <li><router-link href="javascript:;" to="/Careers">Careers</router-link></li>
                            <li><router-link href="javascript:;" to="/testimonailList">Testimonials</router-link></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="pl-0 pl-lg-4 mt-5 mt-lg-0">
                        <h5 class="font-weight-600">Contact Us</h5>
                        <ul class="address pb-3 border-bottom mb-3">
                            <li><i class="fa fa-map-marker"></i> 17 Highfield Drive, <br> Hampstead, 21034  United States</li>
                            <li><a href="tel:(123) 456-7890"><i class="fa fa-phone"></i> (123) 456-7890</a></li>
                            <li><a href="mailto:info@zorbis.com"><i class="fa fa-envelope"></i> info@zorbis.com</a></li>
                        </ul>
                        <ul class="list-unstyled footer-links">
                            <li><router-link href="javascript:;" to="/TermsandConditions"> Terms and Conditions</router-link></li>
                            <li><router-link href="javascript:;" to="/PrivacyPolicy">Privacy Policy</router-link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-bottom">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="block border-top text-center content">

                            Copyright &#169; {{new Date().getFullYear()}} &amp; BeeNee | All rights reserved.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>-->

</template>
<script>
    import UserFrontendService from '@/service/FrontendService.js'
    import { Form, Field } from 'vee-validate';
    import * as Yup from 'yup';
    export default {
        name: 'FooterView',
        components: {
            Form, Field
        },
        props: ['footerNavLinks1', 'footerNavLinks2', 'footerNavLinks3', 'FooterworkusNavLinks', 'FooterLogoDescription', 'isUserLoggedIn','currentYear'],
        data() {
            const searchModel = {
                searchItem: null,
                status: -1,
                sortColumn: 'CreatedOn',
                sortDir: 'desc',
                pageIndex: 0,
                pageSize: 10,
                userId: localStorage.getItem("userId"),
                companyId: localStorage.getItem("SupcompanyId")
            }
            const schemaemail = Yup.object().shape({
                email: Yup.string().required('Email is required').email('Email is invalid').matches('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$', 'Email is invalid'),
            });
            return {
                socialmedialinkList: [],
                searchModel,
                TopProductCatgoryList: null,
                isMarketPlace: true,
                IsFeateured: true,
                schemaemail,
                emailvalue: null,
                //Email:null,
                //Phone:null,
                //footerContent:null,
                companyphone: localStorage.getItem('companyphone'),
                companyemail: localStorage.getItem('companyemail'),
                companyfootercontent: localStorage.getItem('companycontent'),
                companySecondaryLogo: '',
                IsTopDawg: localStorage.getItem("IsTopDawg"),
                telPhone:null
            }
        },
        created() {
            this.getListOfSocialLinks();
            this.getCompanyLogo();
            if (this.companyphone == "(___) ___-____") {
                this.companyphone = null;
            }
            else {
                this.telPhone = this.companyphone != null ? this.companyphone.replace(/[ \(\),\-]/g, ''):null
            }
            this.GetTopProductCatgoryList();

        },
        beforeUnmount() {
            window.removeEventListener('storage', this.handleStorageChange);
        },
        mounted() {
            window.addEventListener('storage', this.handleStorageChange);
            this.GetTopProductCatgoryList();
        },
        methods: {
            getCompanyLogo() {
                let logoUrl = localStorage.getItem('companySecondaryLogo'); //localStorage.getItem('companySecondaryLogo');
                this.companySecondaryLogo = (this.$filters.IsNull(logoUrl, 'null') == 'null') ? require('../../assets/images/logo.png') : logoUrl;
            },
            scrollToTop() {
                window.scrollTo(0, 0);
            },
            getListOfSocialLinks() {
                this.isLoding = true;
                UserFrontendService.GetSocialLinkList(this.searchModel.searchItem, this.searchModel.sortColumn, this.searchModel.sortDir, this.searchModel.pageIndex, this.searchModel.pageSize, '00000000-0000-0000-0000-000000000000', '00000000-0000-0000-0000-000000000000', 1).then(response => {
                    console.log('response', response);
                    this.socialmedialinkList = response.data.data;
                })
                setTimeout(() => { this.isLoding = false; }, 1000);

            },
            GetTopProductCatgoryList() {
                this.isLoading = true;
                this.isMarketPlace = true;
                this.companyId = '00Test';
                this.IsFeateured = false;
                UserFrontendService.GetTopFiveCategory(this.isMarketPlace, this.companyId, this.IsFeateured).then((response) => {
                    if (this.$filters.IsNull(response.data) != null) {
                        console.log('data', response.data);
                        this.TopProductCatgoryList = response.data
                    }
                    this.isLoading = false;
                }).catch((error) => {
                    this.isLoading = false;
                })
            },
            Productdetails(Id) {
               // debugger;
                //this.scrollToTop();
                this.$router.push({ path: `/Products/${Id}` });

            },
            redirectotrackorder() {
                this.scrollToTop();
                this.$router.push({ path: `/OrderTracking` })
            },
            redirectoreturnpolicy() {
                this.scrollToTop();
                this.$router.push({ path: `/ReturnPolicy` })
            },
            redirectodeliveryoption() {
                this.scrollToTop();
                this.$router.push({ path: `/DeliveryOptions` })
            },
            redirectotaxexemption() {
                this.scrollToTop();
                this.$router.push({ path: `/TaxExemptions` })
            },
            redirectocontact() {
                this.scrollToTop();
                this.$router.push({ path: `/contactus` })
            },
            redirectoFaq() {
                this.scrollToTop();
                this.$router.push({ path: `/FAQ` })
            },
            async emailsubmit() {
               // debugger;
                this.isLoading = true;
                var manageSubscribersModel = {
                    email: this.emailvalue,
                     host: "https://" + window.location.host
                }
                await UserFrontendService.AddSubscriber(manageSubscribersModel).then(Response => {
                   // debugger;
                    if (Response != null && Response.data.statusCode == 200 || Response.data.statusCode == 201) {
                        this.isLoading = false;
                        if (Response.data.statusCode == 200) {
                            this.$router.push({
                                name: `thankyou`,
                                state: {
                                    routeMessage: {
                                        isSuccess: true,
                                        displayMessage: 'You have successfully joined our subscriber list'
                                    }
                                }
                            }
                            )
                        }
                        else {
                            this.$router.push({
                                name: `thankyou`,
                                state: {
                                    routeMessage: {
                                        Email: this.emailvalue,
                                        isSuccess: false,
                                    }
                                }
                            });
                        }
                    }
                    else {
                        this.isLoading = false;
                        this.error('Bad req')
                    }


                }).catch(res => {
                    this.error("Something went wrong. Please contact site administrator.");
                    this.isLoading = false;
                })
                this.$refs.refData.resetForm();

            }


        }
    }

</script>
